import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MainLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Metodo`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`I `}<a parentName="p" {...{
            "href": "/it/tranciati-in-legno"
          }}>{`tranciati in legno`}</a>{` di Eredi Monticelli si differenziano per l'elevata qualità e pregio conferiti da un sistema di lavorazione a metodo tradizionale.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ogni tronco è selezionato personalmente da noi. Dopo un processo di condizionatura al vapore, i tronchi vengono trasformati in tranciati di vario spessore. La qualità del taglio è ottima. La fase di asciugatura avviene in modo completamente naturale senza l'utilizzo di aria forzata. Questo sistema richiede tempi di asciugatura maggiori e conferisce al tranciato una particolare consistenza, malleabilità e robustezza. Inoltre permette di mantenere inalterata la naturale colorazione del legno.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Abbiamo disponibilità di un vasto assortimento di tranciati in legno pronti per la consegna negli spessori di 0,6mm, 1mm e 2mm. Su richiesta, siamo a disposizione per produrre tranciati in spessori diversi per soddisfare particolari esigenze.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      